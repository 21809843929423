import React, {Component} from 'react';
import './ceoMessage.css';
import Header from '../../Components/Header/header';
import Footer from '../../Components/Footer/footer';
import CEOImage from '../../assets/img/CEO.png';

export default class CEOMessage extends Component {

    componentDidMount () {
        // const script = document.createElement("script");
    
        // script.src = 'assets/js/main.js';
        // script.async = true;
    
        // document.body.appendChild(script);
    }

  render() {
    return (
        <div>
            <Header/>
            {/* ======= Hero Section ======= */}
            <section id="ceo"  className="d-flex align-items-center ceo-mess">
                <div className="container" data-aos="zoom-out" data-aos-delay={100}>
                <h1>CEO's MESSAGE</h1>
                </div>
            </section>
            {/* End Hero */}
            <main id='main'>
                {/* ======= CEO Section ======= */}
                <section id="about" className="about section-bg">
                <div className="container ceo-section" data-aos="fade-up">
                    <div className="section-title">
                        {/* <h3> <span>CEO MESSAGE</span></h3> */}
                        <img className="col-md-5 col-8 mt-4" src={CEOImage}></img>
                        <p className='mt-5 para'>
                            Non-Performing Assets of the Banking Industry have persistently shown a rising trend over the last several years and are a source of grave concern and a major stumbling block in the way of rapid economic growth.  These NPAs are expected to further augment in the wake of current economic meltdown, political uncertainty and COVID-19 Impact.  Therefore, given the present overall economic situation of the country, there is a dire need to take extraordinary steps to expedite resolution of NPAs and make distressed companies financially and operationally viable.
                        </p>
                        <p className='para'>
                            An empowered, professionally-run Corporate Restructuring Company, duly-supported by regulatory framework and legal set-up was needed to provide both the banking industry and viable but currently-distressed businesses with a much-needed tool.  
                        </p>
                        <p className='para'>
                            Establishment of PCRCL is a step in the right direction to achieve its objectives with a focused approach. The Company, being a specialized set-up, can play a pivotal role in isolating NPAs from the balance sheets of Banks/FIs and facilitate them to concentrate on their core banking activities.
                        </p>
                        <p className='para'>
                            PCRCL will have a competitive edge, owing to its first-mover advantage in the industry. Based on its overall mandate, highly professional management and abundant potential for revival and rehabilitation of sick industrial units & businesses, it is expected to contribute enormously to the Government’s endeavor to revive the economy at the earliest possible.
                        </p>

                        <p className='para mt-3'>
                            <h3 style={{float:'right', fontSize: '25px', fontFamily: 'IcoFont'}}><i>Laqa Sarwar</i></h3>
                        </p>
                    </div>
                    
                </div>
                </section>{/* End CEO Section */}
            </main>
            
            <Footer/>
            {/* <div id="preloader" /> */}
        </div>
    );
  }
}