import React, {Component} from 'react';
import './Leadership.css';
import Header from '../../Components/Header/header';
import Footer from '../../Components/Footer/footer';
import CEOImage from '../../assets/img/CEO.png';
import leader1 from '../../assets/img/Mr-Imran-Butt.jpg';
import leader2 from '../../assets/img/Mr-Tariq-Maqbool.jpg';
import leader3 from '../../assets/img/Mr-Khalid-bin-Sami.jpg';

export default class Leadership extends Component {

    componentDidMount () {
        console.log("hello")
        // const script = document.createElement("script");
    
        // script.src = 'assets/js/main.js';
        // script.async = true;
    
        // document.body.appendChild(script);
    }

  render() {
    return (
        <div>
            <Header/>
            {/* ======= Hero Section ======= */}
            <section id="overview"  className="d-flex align-items-center ceo-mess">
                <div className="container" data-aos="zoom-out" data-aos-delay={100}>
                    <h1>Welcome to <span>PCRCL
                    </span></h1>
                    <h2 style={{textAlign:'center'}}><i>"Transforming the Latent to Potent"</i></h2>
                </div>
            </section>
            {/* End Hero */}
            <main id='main'>
                {/* Leadership Team */}
                <section id="team" className="team section-bg" style={{backgroundColor: 'white'}}>
                <div className="container" data-aos="fade-up">
                    <div className="section-title">
                    <h3 m>LEADERSHIP AT PCRCL</h3>
                    </div>
                    <div className='row justify-content-sm-center mt-5'>
                        <div className="col-4 justify-content-sm-center" data-aos="fade-up" data-aos-delay={100}>
                            <img style={{borderRadius: '80px'}} src={CEOImage} className="img-fluid img-col" alt="" />
                        </div>
                        <div className="col-8 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay={100}>
                            <p>
                            <h5>Mr. Laqa Sarwar</h5>
                            <h5 className='mb-3'>Chief Executive Officer</h5>
                            Mr. Laqa Sarwar has over 28 years of diversified and extensive experience of Textiles Industry and Banking covering Retail, Corporate Credits, Investment Banking & Overall Special Assets Management i.e. Rescheduling, Restructuring & Reengineering of non-performing loans and revival of sick industrial units & businesses. Before joining PCRCL, Mr. Sarwar worked with MCB Bank Limited for 11 years as SEVP/Group Head Asset Remedial Management Group. Prior to that he has worked in various key and middle management positions with UBL, HBL and Corporate & Industrial Restructuring Corporation (CIRC) -A corporation set up by the Federal Government for acquisition, restructuring, rehabilitation, management, disposition, and realization of non-performing loans and other assets of various banks and financial institutions.
                            <br/><br/>
                            Mr. Sarwar is skilled in planning, deal structuring, corporate communications, project appraisals, feasibility studies, financing and monitoring. Proven leader, people motivator and problem solver, able to identify potential and develop & train teams to achieve the targeted results.
                            <br/><br/>
                            Mr. Sarwar’s entire career is a continuing achievement of high level performance at every level. Results achieved are a testament to his expertise in strategic planning, implementation and management with demonstrated ability to lead from the front. Working experience of over 22 years of Special Assets Management has lent Mr. Sarwar a rare insight into the area of revival & rehabilitation of sick industrial units & businesses.
                            <br/><br/>
                            Mr. Sarwar holds M.S. in Statistics from Pakistan and MBA (Finance) from the United States of America. He has also attended and conducted numerous workshops and conventions that were designed specifically around corporate restructurings. His diverse exposure in this field renders him par excellence across the industry.    
                            </p>
                        </div>
                    </div>

                    <div className='row justify-content-sm-center mt-5'>
                        <div className="col-8 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay={100}>
                            <p>
                            <h5>Mr. Imran Butt</h5>
                            <h5 className='mb-3'>Chief Financial Officer/ Company Secretary</h5>
                            Mr. Imran Butt has over 23 years of rich experience in banking and financial industry. He possesses strong technical and analytical skills and has demonstrated eminency in various areas of business management. Mr. Imran’s stellar track record in Operations, Leadership, Controls, and Strategy makes him an exceptional expert in the field.
                            <br/><br/>
                            He started his career at Ernst & Young in 1997 where his responsibilities included carrying out Statutory Audits, Special-PurposeReviews (including Treasury and other Functions’ Internal Audits) and advising on Internal Controls & Risk Management Functions of various banks and financial institutions like First International Investment Bank, Jehangir Siddiqui Investment Bank, American Express Bank, ABN AMRO Bank, Citibank, Oman International Bank, Adamjee Insurance, New Jubilee Insurance, Century Insurance, National Development Finance Corporation, Private Energy Division, Long-term Credit Fund, and National Assets Leasing Company. He later took on the roles of Chief Financial Officer, Company Secretary, Head of Internal Controls, Risk Head, and Head of Business Planning, Strategy,& Analytics at various local and multinational banks and financial institutions, like First International Investment Bank, Bank Al-Habib, NIB Bank, and Samba Bank.
                            <br/><br/>
                            During his professional career, Mr. Imran has frequently been dealing directly with leading international funding agencies and financial institutions, namely, International Financial Corporation, World Bank; American Express, USA; Temasek, Singapore; Fullerton Financial Holding, Singapore and Samba Financial Group, Saudi Arabia.
                            <br/><br/>
                            Mr. Imran holds degrees from The Chartered Institute of Management Accountants, UK and the Institute of Chartered Accountants of Pakistan.    
                            </p>
                        </div>
                        <div className="col-4 justify-content-sm-center" data-aos="fade-up" data-aos-delay={100}>
                            <img style={{borderRadius: '80px'}} src={leader1} className="img-fluid img-col" alt="" />
                        </div>
                    </div>

                    <div className='row justify-content-sm-center mt-5'>
                        <div className="col-4 justify-content-sm-center" data-aos="fade-up" data-aos-delay={100}>
                            <img style={{borderRadius: '80px'}} src={leader2} className="img-fluid img-col" alt="" />
                        </div>
                        <div className="col-8 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay={100}>
                            <p>
                            <h5>Mr. Tariq Maqbool</h5>
                            <h5 className='mb-3'>Head Business Risk & Strategy</h5>
                            Mr. Tariq Maqbool  is a Risk Management professional with 20 plusyears   of   Financial   Risk   Management   and   Regulatory   Complianceexperience in global and local financial services industry.
                            <br/><br/>
                            Mr. Tariq started his banking career with ABN AMRO North America in1997   as   a   Credit   Risk   Analyst   and   worked   at   senior   managementlevels   in   Risk   and   Compliance   Divisions   before   leaving   the   bank   in2005.   Mr.   Tariq   also   worked   as   Senior   Risk   Consultant   in   ResearchDivision   of   Morgan   Stanley,   and   as   Senior   Manager   in   RiskManagement   Division   of   Depository   Trust   and   Clearing   Corporation(DTCC) in New York, USA. 
                            <br/><br/>
                            In 2007 he joined UBS Wealth Management USA as Director Risk andQuality Assurance where he was responsible for managing Risks andCompliance oversight for all Business Segments, Support Groups, andProducts in the USA and North American Regions. 
                            <br/><br/>
                            In  Pakistan,  Mr. Tariq served as a Chief Risk Officer at Askari Bank,HabibMetro Bank, and Bank of Punjab. He also served as GH SAM atBank   of   Punjab   and   Askari   Bank,   Country   Credit   Head   at   Bank   ofPunjab, and GH Compliance at HabibMetro Bank and Bank of Punjab.
                            <br/><br/>
                            Most  recently he was working as CEO PACRA Analytics, an  AdvisoryArm   of   PACRA   set   up   to   help   risk   professionals   make   informeddecisions and build successful strategies. He also served as DirectorFirst Punjab Modarba and Askari Investment.
                            <br/><br/>
                            Mr. Tariq has broad exposure to all aspects of local and international banking and financial regulations, and risk management frameworks. He earned executive management credentials at Columbia Business School, the Kellogg Business School, and the Chicago Business School.  He holds an MA in Economics from BZ University, Multan, an MA in Applied Economics from Western Michigan University, USA, and an MS in MIS from Ferris State University, USA.   
                            </p>
                        </div>
                    </div>

                    <div className='row justify-content-sm-center mt-5'>
                        <div className="col-8 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay={100}>
                            <p>
                            <h5>Mr. Khalid bin Sami</h5>
                            <h5 className='mb-3'>Head Human Resources and Administration</h5>
                            A highly motivated and goals-oriented individual, Mr. Khalid Bin Sami is a seasoned banker. He has worked on all levels, ranging from Branch and Area Manager to Chief of Regional Operations. He comes with a rich experience in Human Resources, MIS & Automation, Internal Control & Compliance, Employment Relation as well as conducting investigations and inquiries. 
                            <br/><br/>
                            He has an MBA in Human Resource Management/ Personnel Administration & Finance from Hamdard University and a Masters in Political Science and Government.
                            </p>
                        </div>
                        <div className="col-4 justify-content-sm-center" data-aos="fade-up" data-aos-delay={100}>
                            <img style={{borderRadius: '80px'}} src={leader3} className="img-fluid img-col" alt="" />
                        </div>
                    </div>

                    {/* <div className='row mt-5'>
                        <div className='col-md-4'>
                            <img src={leader1} className="img-fluid img-col-lower" alt="" />
                            <div className="justify-content-sm-center mt-3" style={{textAlign: 'center'}}>
                                <h5 style={{fontSize:'18px'}}>Mr. Imran Butt</h5>
                                <h5 style={{fontSize:'15px'}}>Chief Financial Officer/ Company Secretary</h5>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <img src={leader2} className="img-fluid img-col-lower" alt="" />
                            <div className="justify-content-sm-center mt-3" style={{textAlign: 'center'}}>
                                <h5 style={{fontSize:'18px'}}>Mr. Tariq Maqbool</h5>
                                <h5 style={{fontSize:'15px'}}>Head Business Risk & Strategy</h5>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <img src={leader3} className="img-fluid img-col-lower" alt="" />
                            <div className="justify-content-sm-center mt-3" style={{textAlign: 'center'}}>
                                <h5 style={{fontSize:'18px'}}>Mr. Khalid bin Sami</h5>
                                <h5 style={{fontSize:'15px'}}>Head Human Resources and Administration</h5>
                            </div>
                        </div>
                    </div> */}

                </div>

                </section>{/* End Team Section */}
            </main>
            
            <Footer/>
        </div>
    );
  }
}