import React, {Component} from 'react';
import './overview.css';
import Header from '../../Components/Header/header';
import Footer from '../../Components/Footer/footer';
import CEOImage from '../../assets/img/CEO.png';
import D1 from '../../assets/directors/Mr_JavedKureishi_m.png';
import D2 from '../../assets/directors/Ms_Shomaila_Loan_r.png';
import D3 from '../../assets/directors/Mr_YameenKerai_w.png';
import D4 from '../../assets/directors/Mr_Aamir_Irshad_.png';

import D5 from '../../assets/directors/Mr_Farid_Ahmad_.png';
import D6 from '../../assets/directors/Mr_Asif_Bashir_bb.png';
import D7 from '../../assets/directors/Mr_Usman_Shahid_be.png';
import D8 from '../../assets/directors/Mr_Farooq_A_Khan_bh.png';

export default class CEOMessage extends Component {

    componentDidMount () {
        // const script = document.createElement("script");
    
        // script.src = 'assets/js/main.js';
        // script.async = true;
    
        // document.body.appendChild(script);
    }

  render() {
    return (
        <div>
            <Header/>
            {/* ======= Hero Section ======= */}
            <section id="overview"  className="d-flex align-items-center ceo-mess">
                <div className="container" data-aos="zoom-out" data-aos-delay={100}>
                <h2>
                    <i>
                        "Pakistan Corporate Restructuring Company Limited (PCRCL) has collectively been established by top ten banks of the country, namely Allied Bank, Bank Alfalah, Bank Al-Habib, Faysal Bank, Habib Bank, Habib Metropolitan Bank, Muslim Commercial Bank, Meezan Bank, National Bank and United Bank, as a public unlisted company"
                    </i>
                </h2>
                </div>
            </section>
            {/* End Hero */}
            <main id='main'>
                {/* Leadership Team */}
                <section id="team" className="team section-bg" style={{backgroundColor: 'white'}}>
                <div className="container" data-aos="fade-up">
                    <div className="section-title">
                    <h3 m>DIRECTORS PCRCL</h3>
                    </div>
                    <div className='row justify-content-sm-center mt-5'>
                        <div className="col-4 justify-content-sm-center" data-aos="fade-up" data-aos-delay={100}>
                            <img src={D1} className="img-fluid img-col" alt="" />
                        </div>
                        <div className="col-8 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay={100}>
                            <p>
                            <h5>Mr. Javed Kureishi</h5>
                            <h5 className='mb-3'>Chairman</h5>
                            Mr. Javed is a career banker having spent 34 years with Citibank both in Pakistan and abroad across 5 countries in Middle East, Africa, Eastern Europe and Asia. Javed held a number of senior positions across Corporate Banking, Country, Risk and Regional Management. This included Corporate Bank Head, Middle East, Chief Executive Officer, Czech Republic, Asia Pacific Regional Head for Multinational Subsidiaries, and Asia Pacific Head of Public Sector. Javed has extensive knowledge of Asia Pacific in particular having travelled to China 35 times. Javed also working as a Senior Consultant to the International Finance Corporation (IFC) in Pakistan since Nov 2019. 
                            <br/><br/>
                            Javed holds a BA Hons in Economics from The University of Sussex, UK. He is currently serving as Chairman on the Board of Directors of Pakistan Corporate Restructuring Company Limited.  
                            </p>
                        </div>
                    </div>
                    
                    <div className='row justify-content-sm-center mt-5'>
                        <div className="col-8 d-flex align-items-stretch mt-5" data-aos="fade-up" data-aos-delay={100}>
                            <p>
                            <h5>Ms. Shomaila Loan</h5>
                            <h5 className='mb-3'>Independent Director</h5>
                            Shomaila Loan joined Engro Corporation as Chief Legal Officer and Company Secretary in August 2020. <br/><br/>
                            An Advocate of the High Courts of Pakistan, Shomaila has worked both as a Corporate/ M&A lawyer with top-ranking law firms as well an in-house. She served as Chief Legal Counsel/Head of Legal for HBL from 2014 to 2018, prior to which she worked with Haidermota & Co., and Vellani & Vellani. She has advised and represented major domestic and foreign companies on a wide range of matters including M&A, corporate, banking law, financing/security arrangements, regulatory matters, competition law and securities offerings.<br/><br/>
                            With a Masters in Corporate Governance & Practice from Stanford Law School (USA), she also holds an L.L.B (Hons) from the University of London (UK), and a Bachelors in Economics from Smith College (USA). Shomaila holds a Certificate of Director Education from the Pakistan Institute of Corporate Governance, and serves as an Independent Director on the Board of Directors of Pakistan Corporate Restructuring Company Limited. She is also a member of the Executive Committee of Public Interest Law Association of Pakistan.<br/>

                            </p>
                        </div>
                        <div className="col-4 justify-content-sm-center mt-5" data-aos="fade-up" data-aos-delay={100}>
                            <img src={D2} className="img-fluid img-col" alt="" />
                        </div>
                    </div>

                    <div className='row justify-content-sm-center mt-5'>
                        <div className="col-4 justify-content-sm-center mt-5" data-aos="fade-up" data-aos-delay={100}>
                            <img src={D3} className="img-fluid img-col" alt="" />
                        </div>
                        <div className="col-8 d-flex align-items-stretch mt-5" data-aos="fade-up" data-aos-delay={100}>
                            <p>
                            <h5>Mr. Yameen Kerai</h5>
                            <h5 className='mb-3'>Independent Director</h5>
                            After graduating from the London School of Economics and qualifying as a Chartered Accountant, Yameen continued his career in public accounting in London and in Saudi Arabia before moving to Toronto to perform senior Finance roles at Canada Life Assurance Company.<br/><br/>
                            Subsequently, he moved to Pakistan as CFO of ABN AMRO NV overseeing the transition of the franchise into a leading consumer, corporate and investment bank. He was then appointed Regional CFO-Asia Pacific based in Singapore overseeing Finance functions of ABN AMRO in 11 countries. Yameen moved back to Pakistan as CFO then newly established NIB Bank. During his tenure, NIB executed a transformational business strategy for SME and Consumer segments in Pakistan, acquired PICIC and arranged funding through equity and capital market actions. Yameen retired as President and CEO of NIB Bank in 2017 after its merger with MCB Bank.<br/><br/>
                            He is acting as Independent Director on the Board of Directors of Pakistan Corporate Restructuring Company Limited.  

                            </p>
                        </div>
                    </div>

                    <div className='row justify-content-sm-center mt-5'>
                        <div className="col-8 d-flex align-items-stretch mt-5" data-aos="fade-up" data-aos-delay={100}>
                            <p>
                            <h5>Mr. Aamir Irshad</h5>
                            <h5 className='mb-3'>Director</h5>
                            Aamir Irshad has been appointed as Head Corporate, Commercial & Investment Banking at HBL. Previously, since March 2017, he was serving as Head Branch Banking and led HBL’s growth in the retail and commercial segment. Aamir served as the Group Head of Corporate & Investment Banking from 2014 to 2017 and later as Head of Corporate Banking for three years, overseeing the Bank’s corporate portfolio throughout Pakistan, comprising customer relations in the Central, Nothern and Southern regions.<br/><br/>
                            Prior to joining the Bank, he had worked in various multinational financial institutions, including Standard Charterd Bank and Credit Agricole, primarily as a Corporate Banker. During his tenure, HBL’s Corporate & Investment Banking Group has become the dominant market leader in terms of transactions and asset size.<br/><br/>
                            He holds an MBA with Distinction from the Canterbury Business School, University of Kent. He is currently serving as Director on the Board of Directors of Pakistan Corporate Restructuring Company Limited.  

                            </p>
                        </div>
                        <div className="col-4 justify-content-sm-center mt-5" data-aos="fade-up" data-aos-delay={100}>
                            <img src={D4} className="img-fluid img-col" alt="" />
                        </div>
                    </div>

                    <div className='row mt-5'>
                        <div className='col-md-3 col-6'>
                            <img src={D5} className="img-fluid img-col-lower" alt="" />
                            <div className="justify-content-sm-center mt-3" style={{textAlign: 'center'}}>
                                <h5 style={{fontSize:'18px'}}>Mr. Farid Ahmad</h5>
                                <h5 style={{fontSize:'15px'}}>Director</h5>
                            </div>
                        </div>
                        <div className='col-md-3 col-6'>
                            <img src={D6} className="img-fluid img-col-lower" alt="" />
                            <div className="justify-content-sm-center mt-3" style={{textAlign: 'center'}}>
                                <h5 style={{fontSize:'18px'}}>Mr. Asif Bashir</h5>
                                <h5 style={{fontSize:'15px'}}>Director</h5>
                            </div>
                        </div>
                        <div className='col-md-3 col-6'>
                            <img src={D7} className="img-fluid img-col-lower" alt="" />
                            <div className="justify-content-sm-center mt-3" style={{textAlign: 'center'}}>
                                <h5 style={{fontSize:'18px'}}>Mr. Usman Shahid</h5>
                                <h5 style={{fontSize:'15px'}}>Director</h5>
                            </div>
                        </div>
                        <div className='col-md-3 col-6'>
                            <img src={D8} className="img-fluid img-col-lower" alt="" />
                            <div className="justify-content-sm-center mt-3" style={{textAlign: 'center'}}>
                                <h5 style={{fontSize:'18px'}}>Mr. Farooq A. Khan</h5>
                                <h5 style={{fontSize:'15px'}}>Director</h5>
                            </div>
                        </div>
                    </div>

                </div>

                </section>{/* End Team Section */}
            </main>
            
            <Footer/>
            {/* <div id="preloader" /> */}
        </div>
    );
  }
}