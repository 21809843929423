import React from 'react';
import './App.css';
import {Route, Router, Switch} from "react-router-dom";
import {createBrowserHistory} from "history";

import Home from './Pages/Home/home';
import CEOMessage from './Pages/CEOMessage/ceoMessage';
import Overview from './Pages/Overview/overview';
import Leadership from './Pages/Leadership/Leadership';

const history = createBrowserHistory();
const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
);

function App() {
  return (
    <Router history={history}>
      <React.Suspense fallback={loading()}>
        <Switch>
          
          <Route exact path='/'>
            <Home/>
          </Route>

          <Route exact path='/ceo-message'>
            <CEOMessage/>
          </Route>

          <Route exact path='/overview'>
            <Overview/>
          </Route>

          <Route exact path='/leadership'>
            <Leadership/>
          </Route>

        </Switch>
      </React.Suspense>
    </Router>
  );
}

export default App;
