import React, {Component} from 'react';
import {HashLink} from 'react-router-hash-link';

export default class Footer extends Component {

    render() {
        return (
            <div>
                {/* ======= Footer ======= */}
                <footer id="footer">
                    <div className="footer-top">
                    <div className="container">
                        <div className="row">
                        <div className="col-sm-4 footer-contact">
                            <a href="/" className="logo mr-auto"><img src="assets/img/logo.png" alt="" style={{maxHeight: '70px', marginBottom: '150px'}} /></a>
                        </div>
                        <div className="col-sm-4 footer-links" style={{paddingLeft: '40px'}}>
                            <h4>Category</h4>
                            <ul>
                                <li><a href='/'>Home</a></li>
                                <li><a href="/">Services</a></li>
                                <li><a href="/">Leadership</a></li>
                                <li><a href="/ceo-message">CEO Message</a></li>
                                <li><a href="/overview">Overview</a></li>
                            </ul>
                        </div>
                        <div className="col-sm-4 footer-links">
                            <div className='col-sm-12'>
                                <h4>Head Office</h4>
                                <i className="bx bx-map" /> 5th Floor, PNSC Building, Near Port Grand, <br/>  M.T. Khan Road, Karachi
                            </div>
                            <div className='col-sm-12 mt-3'>
                                <h4>Contact Us</h4>
                                <i className="icofont-envelope" /> info@pcrcl.com.pk <br/>
                                <i className="icofont-phone" /> 021-35632428 | 021-35632429
                            </div>
                        </div>
                        {/* <div className="col-sm-3 footer-links">
                            <div className="col-sm-12">
                                <h4>Registered Office</h4>
                                <i className="bx bx-map" />13th Floor, UBL Building, Jinnah Avenue, Blue Area, Islamabad
                            </div>
                            <div className="col-sm-12 mt-3">
                                <h4>Regional Office</h4>
                                <i className="bx bx-map" />North &amp; Central : 8 Davis Road, Lahore
                            </div>
                        </div> */}
                        </div>
                    </div>
                    </div>
                    </footer>{/* End Footer */}
                    <div className='row lower-footer' style={{textAlign: 'center'}}>
                        <span className='col-sm-12 copyright-message'>
                            A corporate restructuring company set up under Corporate Restructuring Act, 2016
                        </span>
                        <div className="col-sm-12 copyright">
                        {/* <div className="copyright"> */}
                            © Copyright <strong><span>PCRCL</span></strong>-All Rights Reserved
                        {/* </div> */}
                            {/* <div className='col-xs-8 copyright-message'> */}
                            {/* </div> */}
                        </div>
                    </div>
            </div>
        )
    }
}