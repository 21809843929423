import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Header from '../../Components/Header/header';
import Footer from '../../Components/Footer/footer';
import CEOImage from '../../assets/img/CEO.png';
import leader1 from '../../assets/img/Mr-Imran-Butt.jpg';
import leader2 from '../../assets/img/Mr-Tariq-Maqbool.jpg';
import leader3 from '../../assets/img/Mr-Khalid-bin-Sami.jpg';

export default class Home extends Component {
  state= {
    name: "",
    email: "",
    subject: "",
    message: ""
  }

  handleChange = (e)=> {
    this.setState({[e.target.name]: e.target.value });
    // console.log(this.state[e.target.name])
  }

  sendForm = ()=> {
    const {name, email, subject, message} = this.state;
    if(name && email && subject && message) {
      fetch('https://email-server-fse.herokuapp.com/send-from-pcrcl', {
        method: 'POST',
        headers:{
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          name, email, subject, message
        })
      }).then(result=> {
        console.log({result});
        alert("Your message is sent to PCRCL successfully.");
      })
      .catch(err=> {
        console.log({err});
      })
    }
    else {
      alert("All fields are required");
    }
  }

  componentDidMount () {
    // const script = document.createElement("script");

    // script.src = "assets/js/main.js";
    // // script.async = true;

    // document.body.appendChild(script);
  }

  render() {
    return (
      <div>
          <Header/>
          {/* ======= Hero Section ======= */}
          <section id="hero" className="d-flex align-items-center">
            <div className="container" data-aos="zoom-out" data-aos-delay={100}>
              <h1>Welcome to <span>PCRCL
                </span></h1>
              <h2><i>"Transforming the Latent to Potent"</i></h2>
            </div>
          </section>{/* End Hero */}
          <main id="main">
            {/* ======= Featured Services Section ======= */}
            <section id="featured-services" className="featured-services">
              <div className="container" data-aos="fade-up">
                <div className="section-title">
                  {/* <h2>About</h2> */}
                  <h3>Corporate <span>Restructuring</span></h3>
                  <p>Pakistan Corporate Restructuring Company is a specialized set-up, established with the goal of isolating non-performing assets from the balance sheets of banks and financial institutions in order to make distressed industrial units financially and operationally viable, thereby generating employment and lifting, especially, the COVID-induced economic chill in the country.</p>
                </div>
              </div>
            </section>{/* End Featured Services Section */}
            {/* ======= About Section ======= */}
            <section id='about' className="about section-bg">
              <div className="container" data-aos="fade-up">
                <div className="section-title">
                  <h3> <span>About Us</span></h3>
                  {/* <h2>CEO's Message</h2> */}
                  <p>Given the present overall economic situation of the country, there was a dire need to take extraordinary steps to expedite resolution of Non-Performing Assets of the Banks/FIs and make distressed companies financially and operationally viable.  To meet this end, Pakistan Corporate Restructuring Company Limited (PCRCL), has been incorporated and licensed under the Corporate Restructuring Companies Act, 2016, with a mandate to revive, restructure and rehabilitate the sick industrial units & businesses in Pakistan.</p>
                  <p>The Company has collectively been established by the top ten(10) Private Sector Banks of Pakistan namely Allied Bank Limited, Bank Alfalah Limited, Bank Al Habib Limited, Faysal Bank Limited, Habib Bank Limited, Habib Metropolitan Bank Limited, MCB Bank Limited, Meezan Bank Limited, National Bank of Pakistan and United Bank Limited (collectively, <b>the “Sponsors”</b>)</p>
                </div>
                <div className="row">
                  <div className="col-lg-6" data-aos="zoom-out" data-aos-delay={100}>
                    <img src="assets/img/about2.jpg" className="img-fluid" alt="" />
                  </div>
                  <div className="col-lg-6 pt-0 pt-lg-0 content d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-delay={100}>
                    <ul>
                      <li>
                        <i className="bx bx-store-alt" />
                        <div>
                          <h5>Pakistan Corporate Restructuring Company Limited (PCRCL)</h5>
                          <p>PCRCL is the first private-sector company in Pakistan formed with the very purpose of transforming the financially distressed loans and companies to financially and operationally viable assets and is empowered to acquire, buy, hold, manage, restructure, reschedule, resolve, settle, recover, assign, transfer and dispose of non-performing assets of the financial institutions.   
                          <br/><br/>PCRCL has been established and administered for achieving the broad objectives of Revival / Rehabilitation of potentially viable sick industrial units / businesses, Cleansing of books of the Banks, building industry expertise in Distressed Debt Resolution and capacity building for the Banks to lend to Agriculture, SME, Housing and Corporate Sectors etc. and to serve as a catalyst for important legal reforms for recovery & revival of NPAs.</p>
                        </div>
                      </li>
                    </ul>
                    <button className="btn-readmore" type="button" data-toggle="modal" data-target="#aboutUsModal">Read More</button>       
                  </div>
                </div>
              </div>
            </section>{/* End About Section */}
            {/* ======= Testimonials Section ======= */}
            <section id="testimonials" className="testimonials">
              <div className="container" data-aos="zoom-in">
                <div className="owl-carousel testimonials-carousel">
                  <div className="testimonial-item">
                    <h3>Our Vision</h3>
                    <p>
                      <i className="bx bxs-quote-alt-left quote-icon-left" />
                      To become a vibrant economic agent, contributing towards the revival of sick industrial units / businesses and generating employment opportunities in the country vis-à-vis reducing the stock of non-performing assets across the banking industry of Pakistan.
                      <i className="bx bxs-quote-alt-right quote-icon-right" />
                    </p>
                  </div>
                  <div className="testimonial-item">
                    <h3>Our Mission</h3>
                    <p>
                      <i className="bx bxs-quote-alt-left quote-icon-left" />
                      We are a team of committed professionals, engaged in the rehabilitation of sick industries in the country in line with best global practices and transparency standards, whilst specializing in the resolution and management of distressed assets.
                      <i className="bx bxs-quote-alt-right quote-icon-right" />
                    </p>
                  </div>
                </div>
              </div>
            </section>{/* End Testimonials Section */}
            {/* ======= Services Section ======= */}
            <section id="services" className="services">
              <div className="container" data-aos="fade-up">
                <div className="section-title">
                  <h3>Our <span>Services</span></h3>
                  <p />
                </div>
                <div className="row">
                  <div className="col-lg-4 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay={100}>
                    <div className="icon-box">
                      {/* <div className="icon"><i className="bx bx-dollar-circle" /></div> */}
                      <h4><a href>Asset Recovery</a></h4>
                      <p>Our value-driven approach towards recovering non-performing assets of financial institutions across the country encompasses original, efficient and effective strategies that have been specifically engineered for this purpose and are directed at addressing the grave challenges these businesses currently face. These tools include out-of-court workouts, loan restructuring and/ or rescheduling and business reorganizations.</p>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0" data-aos="zoom-in" data-aos-delay={200}>
                    <div className="icon-box">
                      {/* <div className="icon"><i className="bx bxs-factory" /></div> */}
                      <h4><a href>Industry Revival</a></h4>
                      <p>With especially-designed instruments in place, we aim to restructure, revive and rehabilitate the financially distressed industries in the country. These include, but not limited to, building industry expertise in Distressed Debt Resolution, capacity building for banks and financial institutions for lending to country’s productive sectors such as Agriculture, SME, Housing and Corporates and catalyzing important legal reforms for recovery and revival of non-performing assets.</p>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0" data-aos="zoom-in" data-aos-delay={300}>
                    <div className="icon-box">
                      {/* <div className="icon"><i className="bx bx-user-voice" /></div> */}
                      <h4><a href>Advisory</a></h4>
                      <p>Our advisory arm is committed to providing corporates and financial institutions  with practical financial solutions to facilitate organizational governance, talent acquisition, portfolio management and implementation of suitable business strategies. Our services extend to advancing, supporting and raising finances for rehabilitation, restructuring and reorganization.</p>
                    </div>
                  </div>
                </div>
              </div>
            </section>{/* End Services Section */}
            {/* Leadership Team */}
            <section id="team" className="team section-bg" style={{backgroundColor: 'white'}}>
              <div className="container" data-aos="fade-up">
                <div className="section-title">
                  <h3 m>Meet our  <span>Leadership</span></h3>
                </div>
                <div className='row justify-content-center'>
                  <div className="col-lg-4 col-md-6 col-8 d-flex align-items-stretch justify-content-sm-center" data-aos="fade-up" data-aos-delay={100}>
                    <div className="member">
                      <div className="member-img">
                        <img src={CEOImage} className="img-fluid" alt="" />
                      </div>
                      <div className="member-info">
                        <h4>Mr. Laqa Sarwar</h4>
                        <span style={{maxWidth:'200px'}}>Chief Executive Officer</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row justify-content-center mb-5'>
                  <div className='col-xs-3'>
                    <a href='/ceo-message' style={{textDecoration: 'none', justifyContent:'center', cursor:'pointer'}} className='mt-1 d-flex'>
                    <h2 className="contactus" style={{marginTop: '5px', marginLeft: '20px'}}>CEO's MESSAGE</h2></a>
                  </div>
                </div>
                <div className="row justify-content-sm-center">
                  <div className="col-lg-3 col-6 d-flex align-items-stretch justify-content-sm-center" data-aos="fade-up" data-aos-delay={200}>
                    <div className="member">
                      <div className="member-img">
                        <img src={leader1} className="img-fluid" alt="" />
                      </div>
                      <div className="member-info">
                        <h4>Mr. Imran Butt</h4>
                        <span style={{maxWidth:'200px'}}>Chief Financial Officer/ Company Secretary</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-6 d-flex align-items-stretch justify-content-sm-center" data-aos="fade-up" data-aos-delay={300}>
                    <div className="member">
                      <div className="member-img">
                        <img src={leader2} className="img-fluid" alt="" />
                      </div>
                      <div className="member-info">
                        <h4>Mr. Tariq Maqbool</h4>
                        <span style={{maxWidth:'200px'}}>Head Business Risk &amp; Strategy</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-6 d-flex align-items-stretch justify-content-sm-center" data-aos="fade-up" data-aos-delay={400}>
                    <div className="member">
                      <div className="member-img">
                        <img src={leader3} className="img-fluid" alt="" />
                      </div>
                      <div className="member-info">
                        <h4>Mr. Khalid bin Sami</h4>
                        <span style={{maxWidth:'200px'}}>Head Human Resources and Administration</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row justify-content-sm-center mb-5'>
                  <div className='col-xs-3'>
                    <a href='/leadership' style={{textDecoration: 'none', justifyContent:'center', cursor:'pointer'}} className='mt-4 d-flex'>
                    <h2 className="contactus" style={{marginTop: '5px', marginLeft: '20px'}}>View More</h2></a>
                  </div>
                </div>

              </div>
            </section>{/* End Team Section */}
            {/* ======= Clients Section ======= */}
            <section id="clients" className="clients section-bg">
              <div className="container" data-aos="zoom-in">
                {/* <div class="row"> */}
                {/* <marquee behavior="scroll" direction="left"> */}
                <section className="customer-logos slider">
                  {/* class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center" */}
                  <div>
                    <img src="assets/img/clients/1.png" className="slide img-fluid" alt="" />
                  </div>
                  <div>
                    <img src="assets/img/clients/2.png" className="slide img-fluid" alt="" />
                  </div>
                  <div>
                    <img src="assets/img/clients/3.png" className="slide img-fluid" alt="" />
                  </div>
                  <div>
                    <img src="assets/img/clients/4.png" className="slide img-fluid" alt="" />
                  </div>
                  <div>
                    <img src="assets/img/clients/5.png" className="slide img-fluid" alt="" />
                  </div>
                  <div>
                    <img src="assets/img/clients/6.png" className="slide img-fluid" alt="" />
                  </div>
                  <div>
                    <img src="assets/img/clients/7.jpeg" className="slide img-fluid" alt="" />
                  </div>
                  <div>
                    <img src="assets/img/clients/8.png" className="slide img-fluid" alt="" />
                  </div>
                  <div>
                    <img src="assets/img/clients/9.jpg" className="slide img-fluid" alt="" />
                  </div>
                  <div>
                    <img style={{marginTop: '-8px'}} src="assets/img/clients/10.png" className="slide img-fluid" alt="" />
                  </div>
                </section>
                {/* </marquee> */}
                {/* </div> */}
              </div>
            </section>{/* End Clients Section */}
            {/* ======= Contact Section ======= */}
            <section id="contact" className="contact">
              <div className="container">
                <div className="section-title">
                  <h3><span>Contact Us</span></h3>
                </div>

                <div className="row justify-content-sm-center mb-5">
                  {/* <div className="col-lg-6">
                    <iframe className="mb-4 mb-lg-0" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3620.5778467346763!2d66.99062071500252!3d24.844106484061587!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3eb315fdfe9d2ec3%3A0xab240a3185d15946!2sPNSC%20Building%2C%20Lalazar%20Karachi%2C%20Karachi%20City%2C%20Sindh!5e0!3m2!1sen!2s!4v1608010855982!5m2!1sen!2s" frameBorder={0} style={{border: 0, width: '100%', height: '384px'}} allowFullScreen />
                  </div> */}

                  <div className="col-sm-10">
                    <form className="php-email-form">
                      <div className="form-row">
                        <div className="col form-group">
                          <input type="text" name="name" className="form-control" id="name" onChange={this.handleChange}
                          placeholder="Your Name" data-rule="minlen:4" data-msg="Please enter at least 4 chars" />
                          {/* <div class="validate"></div> */}
                        </div>
                        <div className="col form-group">
                          <input type="email" className="form-control" name="email" id="email" onChange={this.handleChange}
                          placeholder="Your Email" data-rule="email" data-msg="Please enter a valid email" />
                          {/* <div class="validate"></div> */}
                        </div>
                      </div>
                      <div className="form-group">
                        <input type="text" className="form-control" name="subject" id="subject" onChange={this.handleChange}
                        placeholder="Subject" data-rule="minlen:4" data-msg="Please enter at least 8 chars of subject" />
                        {/* <div class="validate"></div> */}
                      </div>
                      <div className="form-group">
                        <textarea className="form-control" name="message" rows="5" onChange={this.handleChange}
                        data-rule="required" data-msg="Please write something for us" placeholder="Message"></textarea>
                        {/* <div class="validate"></div> */}
                      </div>
                      {/* <div class="mb-3">
                        <div class="loading">Loading</div>
                        <div class="error-message"></div>
                        <div class="sent-message">Your message has been sent. Thank you!</div>
                      </div> */}
                      <div className="text-center"><p onClick={this.sendForm}>Send Message</p></div>
                    </form>
                  </div>

                </div>

                <div className="row">
                  <div className="col-lg-6">
                    <div className="info-box mb-4">
                      <i className="bx bx-map" />
                      <h3>Our Address</h3>
                      <p>Head Office : 5th Floor, PNSC Building, Near Port Grand, M.T. Khan Road, Karachi<br />
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div className="info-box  mb-4">
                      <i className="bx bx-envelope" />
                      <h3>Email Us</h3>
                      <p>info@pcrcl.com.pk</p>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div className="info-box  mb-4">
                      <i className="bx bx-phone-call" />
                      <h3>Call Us</h3>
                      <p>021-35632428 | 021-35632429</p>
                    </div>
                  </div>
                </div>
              </div></section>{/* End Contact Section */}
          </main>{/* End #main */}
          
          <Footer/>

          {/* CEO's Message Modal */}
          <div className="modal fade" id="aboutUsModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLongTitle">About Us</h5>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body">
                  Target market of PCRCL is the Banks/FIs, which can transfer/assign their NPAs to the company in order to reduce their burden of the huge stock of infected portfolio and release their resources to be employed to other remunerative avenues.
                  <br />
                  <br />
                  The Board of Directors of the Company is comprised of professionals from the Banking & financial sector including senior executives of Five (5) major Banks (HBL, NBP, UBL, MCB & ABL) and Three (3) independent directors with relevant experience in their respective fields.  Management of the Company also has a proven track record for revival of sick industrial units/businesses and recovery of NPAs.
                </div>
                {/* <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            <button type="button" class="btn btn-primary">Save changes</button>
          </div> */}
              </div>
            </div>
          </div>
          {/* <div id="preloader" /> */}
          <a href="#" className="back-to-top"><i className="icofont-simple-up" /></a>
          {/* Vendor JS Files */}
          {/* Template Main JS File */}
        </div>
    );
  }
}